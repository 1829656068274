.video-card{
  width: 350px;
  height: 350px;
  list-style: none;
  box-shadow: 0 4px 10px #000000;
  margin: 20px 0;
  .video-section{
    width: 100%;
    height: 200px;
    iframe{
      width: 100%;
      height: 100%;
    }
  }
  .body-section{
    width: 100%;
    height: 150px;
    background: #F6F6F9;
    padding: 20px;
  }
}
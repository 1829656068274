@font-face {
  font-family: 'Livvic';
  src: url('Livvic-BlackItalic.woff2') format('woff2'),
  url('Livvic-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Livvic';
  src: url('Livvic-Bold.woff2') format('woff2'),
  url('Livvic-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Livvic';
  src: url('Livvic-Black.woff2') format('woff2'),
  url('Livvic-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Livvic';
  src: url('Livvic-BoldItalic.woff2') format('woff2'),
  url('Livvic-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Livvic';
  src: url('Livvic-ExtraLight.woff2') format('woff2'),
  url('Livvic-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Livvic';
  src: url('Livvic-Italic.woff2') format('woff2'),
  url('Livvic-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Livvic';
  src: url('Livvic-ExtraLightItalic.woff2') format('woff2'),
  url('Livvic-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Livvic';
  src: url('Livvic-Medium.woff2') format('woff2'),
  url('Livvic-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Livvic';
  src: url('Livvic-Light.woff2') format('woff2'),
  url('Livvic-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Livvic';
  src: url('Livvic-LightItalic.woff2') format('woff2'),
  url('Livvic-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Livvic';
  src: url('Livvic-Thin.woff2') format('woff2'),
  url('Livvic-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Livvic';
  src: url('Livvic-SemiBoldItalic.woff2') format('woff2'),
  url('Livvic-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Livvic';
  src: url('Livvic-Regular.woff2') format('woff2'),
  url('Livvic-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Livvic';
  src: url('Livvic-MediumItalic.woff2') format('woff2'),
  url('Livvic-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Livvic';
  src: url('Livvic-SemiBold.woff2') format('woff2'),
  url('Livvic-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Livvic';
  src: url('Livvic-ThinItalic.woff2') format('woff2'),
  url('Livvic-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}


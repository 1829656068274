.location {
  width: 100%;
  height: 500px;

  iframe {
    border: none;
    width: 100%;
    height: 500px;
  }
}

.info-container {

  .title {
    color: #010F56;
    font-size: 30px;
    text-align: center;
    margin: 20px 0;
  }

  .box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px 0;
    justify-content: space-between;
    .info {
      width: 55%;
      p{
        font-size: 20px;
      }
    }

    .img {
      width: 40%;

      img {
        width: 400px;
        height: 300px;
        object-fit: cover;
        margin: 10px 0;
      }
    }
  }
}

.video-content{
  .container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px 0;
  }
}


.certificate{
  .container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    img{
      width: 350px;
      margin-bottom: 20px;
    }
  }
}

@media screen and(max-width: 576px) {
  .video-content{
    .container{
      flex-direction: column;
      align-items: center;
      justify-content: normal;
    }
  }
  .info-container{
    .container{
      .box{
        display: flex;
        flex-direction: column;
        align-items: center;
        &:last-child{
          flex-direction: column-reverse;
        }
        .info{
          width: 90%;
        }
        .img{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
  .certificate{
    .container{
      flex-direction: column;
      align-items: center;
    }
  }
}
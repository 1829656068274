.header{
  box-shadow: 4px 10px 10px #ccc;
  padding: 20px 0;
  background: #F6F6F9;
  position: relative;
  z-index: 5;
  .container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .logo{
      img{
        width: 120px;
      }
    }
    .header-linkers{
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      li{
        a{
          display: inline-block;
          padding: 15px 20px;
          background: #295091;
          color: #FFFF;
          font-weight: 600;
          border-radius: 15px;
          transition: all ease 0.5s;
          &:hover{
            opacity: 0.8;
          }
          &:last-child{
            margin-left: 20px;
          }
        }
      }
    }
  }
}

.burger-button{
  display: none;
}

@media screen and(max-width: 576px) {
  .header-linkers{
    display: none !important;
  }
  .header{
    .container{
      padding: 0 30px;
    }
  }
  .burger-button {
    width: 30px;
    height: 30px;
    display: inline-block;
    position: relative;
    z-index: 101;
    border: none;
    background: transparent;
    &-close {
      display: none;
    }

    &.active {
      .burger-button-open {
        display: none;
      }

      .burger-button-close {
        display: block;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }


  }
}
.footer {
  width: 100%;
  background: #010F56;
  color: white;
  padding: 50px 0;

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 100px;
    }

    .phone-number {
      list-style: none;

      li {
        line-height: 2;

        a {
          color: white;
          font-size: 15px;
          position: relative;

          &::before {
            position: absolute;
            content: '';
            background: url("../../assets/images/icons/phone-call.svg");
            width: 20px;
            height: 20px;
            background-size: contain;
            left: -25px;
            top: 0;
            bottom: 0;
            margin: auto 0;
          }
        }
      }
    }

    .email-address {
      list-style: none;

      li {
        line-height: 2;
        a {
          color: white;
          font-size: 15px;
          position: relative;

          &::before {
            position: absolute;
            content: '';
            background: url("../../assets/images/icons/telegram.svg");
            width: 20px;
            height: 20px;
            background-size: contain;
            left: -25px;
            top: 0;
            bottom: 0;
            margin: auto 0;
          }
        }
      }
    }
  }
}

@media screen and(max-width: 768px) {
  .footer {
    .container {
      flex-direction: column;
      align-items: center;
    }
  }
  ul {
    margin: 20px 0;
  }
}
.home-slider{
  width: 100%;
  margin: 0 auto;
  height: 600px;
  .slick-next, .slick-prev{
    display: none !important;
  }
  .slide{
    width: 100%;
    height: 600px;
    position: relative;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .context{
      background: rgba(0, 0, 0, 0.62);
      position: absolute;
      right: 50px;
      bottom: 50px;
      padding: 20px;
      color: white;
    }
  }
}

.product{
  width: 100%;
  padding: 100px 0;
  background: #F6F6F9;
  &-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media screen and(max-width: 576px) {
  .product-wrapper{
    justify-content: center;
  }
  .home-slider{
    width: 100%;
  }
}
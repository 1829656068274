.mobile-menu {
  display: none;
}

@media screen and(max-width: 576px) {
  .mobile-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    background: #FFFFFF;
    padding: 100px 50px;
    transition: all ease 0.5s;
    &.hidden{
      left: -150%;
    }
    .linkers {
      list-style: none;
      display: flex;
      flex-direction: column;

      li {
        text-align: right;
        line-height: 2;

        a {
          display: inline-block;
          color: #010F56;
          font-size: 20px;
        }
      }
    }
  }
}